<template>
  <div>
    <div class="row m-0 p-0" ref="menu">
      <div class="col-12 m-0 p-0" v-for="menuItem in menuItems" :key="menuItem.value">
        <menu-item :menuItem="menuItem" :isActive="isActive(menuItem.value)" @selectItem="selectMenu"/>
      </div>
    </div>
  </div>
</template>
<script>
import { menuItems } from '../helpers/menu.js'

export default {
  name: 'Menu',
  components: {
    'menu-item': () => import('@/components/MenuItem.vue')
  },
  props: ['selectedMenuItem'],
  data () {
    return {
      menuItems: menuItems.filter(mi => !mi.hidden)
    }
  },
  methods: {
    iconSrc (icon) {
      return require(`../assets/images/${icon}`)
    },
    isActive (menuItemValue) {
      return this.selectedMenuItem && this.selectedMenuItem.value === menuItemValue
    },
    selectMenu (menuItem) {
      this.$emit('selectMenu', menuItem)
    }
  }
}
</script>
